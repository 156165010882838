/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import { Link } from 'gatsby';

// UI lib component
import { Container, Row } from 'react-grid-system';

// UI local components
import Title from '../titleSection/index';
import Download from '../../shared/constants/downloads';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                 Downloads section                                 */
/* -------------------------------------------------------------------------- */

function Downloads() {
  /* -------------------------------- HOOKS ----------------------------------- */
  const [searchInput] = useState('');

  /* ******************************** RENDERING ******************************* */
  return (
    <Container id="downloads" className="downloads-section fluid" fluid>
      <Row className="downloads-container flex items-center fluid" fluid>
        <Title title="Téléchargements" />
      </Row>
      <Download searchInput={searchInput} />
      <Link to="/">
        <h6 className="read_more">Tous les documents</h6>
      </Link>
    </Container>
  );
}
export default Downloads;
