/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';

// Fake API
import fakeDownloads from '../fake-APIs/downloads';

// Assets
import PDF_ICON from '../../images/downloads/filePdf.svg';
import DOWNLOAD_ICON from '../../images/downloads/download.svg';

// Style
import '../../page-components/downloads/index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Download                                  */
/* -------------------------------------------------------------------------- */

function Download({ searchInput }) {
  /* ------------------------------ CONST ---------------------------------- */
  const downloads = fakeDownloads
    .filter((filtredDownload) =>
      filtredDownload.title.toLowerCase().includes(searchInput?.toLowerCase()),
    )
    .map((download) => {
      return (
        <Col
          key={download.id}
          className="download-wrapper fluid"
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          fluid
        >
          <div
            className="single-download-container flex justify-between items-center fluid"
            fluid
          >
            <Container className="flex items-center fluid" fluid>
              <img src={PDF_ICON} alt="icon de fichier PDF" />
              <h5>{download.title}</h5>
            </Container>
            <Container className="download-icon fluid" fluid>
              <Link
                to={download.URL}
                className="download-link flex items-center"
              >
                <img src={DOWNLOAD_ICON} alt="icon de telechargement" />
              </Link>
            </Container>
          </div>
        </Col>
      );
    });
  /* ******************************** RENDERING ******************************* */
  return (
    <Container>
      <Row className="downloads-container flex-wrap">{downloads}</Row>
    </Container>
  );
}
export default Download;

Download.propTypes = {
  searchInput: PropTypes.string.isRequired,
};
