const fakeDownloads = [
  {
    id: 1,
    title:
      'Maecenas eget ex facilisis, ultricies lorem luctus, dignissim enim.',
    URL: '/',
  },
  {
    id: 2,
    title:
      'Maecenas eget ex facilisis, ultricies lorem luctus, dignissim enim.',
    URL: '/',
  },
  {
    id: 3,
    title:
      'Maecenas eget ex facilisis, ultricies lorem luctus, dignissim enim.',
    URL: '/',
  },
  {
    id: 4,
    title:
      'Maecenas eget ex facilisis, ultricies lorem luctus, dignissim enim.',
    URL: '/',
  },
];

export default fakeDownloads;
