/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from 'gatsby';

// PropTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Row, Col, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';
// import MenuBar from '../page-components/MenuSection';
import SideMenu from '../page-components/MenuSection/SideMenu';
import HealthPillar from '../page-components/healthPillar';
import Events from '../page-components/Events';
import ActualitiesSectionIndex from '../page-components/page-index/actualities-section';
import PCN from '../page-components/PCN';
import UsefullLinks from '../page-components/usefulLinks';
import Downloads from '../page-components/downloads';

// Constants
import { DUMMY_TEXT } from '../shared/constants/dummytext';

// Style
import '../page-styles/thematic.scss';

/* -------------------------------------------------------------------------- */
/*                           Thematique Page                                  */
/* -------------------------------------------------------------------------- */

function ThematiquePage({ searchInput, location }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  // const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Thématique" />
      <Container className="thematique-page fluid" fluid>
        <BannerSection
          title="Problématiques mondiales et compétitivité industrielle européenne"
          subtitle="2e pilier"
          description={DUMMY_TEXT}
          className="background-image"
        />
        {/* <MenuBar className="grayish-background" /> */}
        <Container>
          <Visible lg xl xxl>
            <Container className="top flex items-center fluid">
              <Link className="breadcrumb__link" to="/">
                Accueil
              </Link>
              <span className="m-r-l">/</span>
              <Link
                className="breadcrumb__link"
                to={`/${location.pathname.split('/')[1]}`}
              >
                {location.pathname.split('/')[1]}
              </Link>
            </Container>
          </Visible>
        </Container>
        <Container>
          <Row className="fluid">
            <Col
              className="menu-container fluid"
              xxl={3}
              xl={3}
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              <SideMenu />
            </Col>
            <Col className="fluid" xxl={9} xl={9} lg={9} md={9} sm={12} xs={12}>
              <HealthPillar />
              <Events />
              <ActualitiesSectionIndex />
              <PCN searchInput={searchInput} />
              <UsefullLinks />
              <Downloads />
            </Col>
          </Row>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

ThematiquePage.propTypes = {
  searchInput: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ThematiquePage;
